import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import WidgetTracker from "../common/components/WidgetTracker";
import { ContactMultiSelectPicker } from "./ContactMultiSelectPicker";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import { ContactDbWidget, Scope } from "../api/contacts/contacts.typings";
import { mapContactDtoToContactOutputDto } from "../common/mappers/contact.output.mappers";
import { ContactOutputDto } from "../common/typings/contacts.typings";
import { WithSGB4 } from "../common/components/WithSGB4";
import { SGBSSize } from "@sgbs-ui/core";
import {
  CommonPickerProps,
  ContactDisplayOption,
  CreateLinkMode,
  CreateLinkPickerProps,
  EmitOnChangeMode,
} from "../common/typings";
import { map } from "lodash-es";
import {
  CONTACT_DISPLAY_OPTION,
  CREATE_LINK_MODE,
  EMIT_MODE,
  MAX_RESULT_COUNT,
  PLACEHOLDER,
  SIZE,
} from "../common/constants";
import { useCreateLinkClicked } from "../common/hooks/useCreateLinkClicked";
import { SgDashboardProps, SgDashboardWidgetProps } from "../common/sgDashboard";
import {
  getInitialMultiSelection,
  notifyMultiChangeToSgDashboard,
  useEmitOnMount,
} from "../common/hooks/useUserSelection";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";

const TAG = "ic-contact-multi-picker";

export interface Props
  extends CommonPickerProps<{ contacts: ContactOutputDto[] }, string>,
    CreateLinkPickerProps,
    SgDashboardProps {
  selectedIds?: string[];
  readonlyIds?: string[];
  internalOnly?: string;
  activeOnly?: string;
  withQualityIndicators?: string;
  useHistory?: string;
  displayOption: ContactDisplayOption;
  scope?: Scope;
  suggestionIds?: string[];
  contactsIds?: string[];
  onClearSuggestions?: (items: ContactDbWidget[]) => void;
  onRemoveSuggestion?: (item: ContactDbWidget) => void;
  additionalFields?: string[];
}

export const ContactsPickerWidget: React.FC<Props> = ({
  id,
  selectedIds,
  readonlyIds,
  errorMessage,
  inError,
  internalOnly,
  activeOnly,
  withQualityIndicators,
  useHistory,
  onReady,
  onChange,
  onCreateLinkClicked,
  maxResultCount = MAX_RESULT_COUNT,
  createLinkMode = CREATE_LINK_MODE,
  placeholder = PLACEHOLDER,
  displayOption = CONTACT_DISPLAY_OPTION,
  size = SIZE,
  emitMode = EMIT_MODE,
  outline,
  disabled,
  createActionText,
  scope,
  contactsIds,
  additionalFields,
  // sg-dashboard
  emitState,
  setUserSelection,
  theme,
  userSelection,
  suggestionIds,
  onClearSuggestions,
  onRemoveSuggestion,
}) => {
  const handleOnSelectionChange = (contacts: ContactDbWidget[] | undefined = []): void => {
    notifyMultiChangeToSgDashboard({ userSelection, setUserSelection, emitState }, contacts);
    onChange?.({ contacts: map(contacts, mapContactDtoToContactOutputDto) });
  };
  useEmitOnMount(userSelection, emitMode, () => onChange?.({ contacts: [] }));
  const initSelection = getInitialMultiSelection(userSelection, selectedIds);

  const handleOnCreateLinkClicked = useCreateLinkClicked(createLinkMode, "contacts", onCreateLinkClicked);
  const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

  return (
    <WithSGB4 theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ContactMultiSelectPicker
          id={id}
          isInternal={convertToBoolOrUndefined(internalOnly)}
          onlyActive={convertToBoolOrUndefined(activeOnly)}
          withQualityIndicators={convertToBoolOrUndefined(withQualityIndicators)}
          useHistory={convertToBoolOrUndefined(useHistory)}
          placeholder={placeholder}
          maxResultCount={maxResultCount}
          selectedIds={initSelection}
          readonlyIds={readonlyIds}
          displayOption={displayOption}
          createLinkMode={createLinkMode}
          emitMode={emitMode}
          scope={scope}
          contactsIds={contactsIds}
          inError={convertToBoolOrUndefined(inError)}
          size={size}
          disabled={convertToBoolOrUndefined(disabled)}
          outline={convertToBoolOrUndefined(outline)}
          errorMessage={errorMessage}
          createActionText={createActionText}
          suggestionIds={suggestionIds}
          onChange={handleOnSelectionChange}
          onReady={onReady}
          onCreateLinkClicked={handleOnCreateLinkClicked}
          onClearSuggestions={onClearSuggestions}
          onRemoveSuggestion={onRemoveSuggestion}
          additionalFields={additionalFields}
        />
        <WidgetTracker widgetName={TAG} />
      </QueryClientProvider>
    </WithSGB4>
  );
};

widgetize(
  TAG,
  ContactsPickerWidget,
  {
    id: WidgetPropsMapping.asString(),
    maxResultCount: WidgetPropsMapping.asNumber(),
    placeholder: WidgetPropsMapping.asString(),
    disabled: WidgetPropsMapping.asString(),
    outline: WidgetPropsMapping.asString(),
    size: WidgetPropsMapping.asString() as PropMapping<Props, SGBSSize>,
    inError: WidgetPropsMapping.asString(),
    errorMessage: WidgetPropsMapping.asString(),
    createLinkMode: WidgetPropsMapping.asString() as PropMapping<Props, CreateLinkMode>,
    createActionText: WidgetPropsMapping.asString(),
    selectedIds: WidgetPropsMapping.asObject(),
    readonlyIds: WidgetPropsMapping.asObject(),
    internalOnly: WidgetPropsMapping.asString(),
    scope: WidgetPropsMapping.asString() as PropMapping<Props, Scope>,
    activeOnly: WidgetPropsMapping.asString(),
    withQualityIndicators: WidgetPropsMapping.asString(),
    useHistory: WidgetPropsMapping.asString(),
    suggestionIds: WidgetPropsMapping.asObject(),
    emitMode: WidgetPropsMapping.asString() as PropMapping<Props, EmitOnChangeMode>,
    displayOption: WidgetPropsMapping.asString() as PropMapping<Props, ContactDisplayOption>,
    contactsIds: WidgetPropsMapping.asObject(),
    additionalFields: WidgetPropsMapping.asObject(),
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
    onCreateLinkClicked: WidgetPropsMapping.asEventEmitter("create-link-clicked"),
    onChange: WidgetPropsMapping.asEventEmitter("changed"),
    onClearSuggestions: WidgetPropsMapping.asEventEmitter("clear-suggestions"),
    onRemoveSuggestion: WidgetPropsMapping.asEventEmitter("remove-suggestion"),
    ...SgDashboardWidgetProps,
  },
  { neverInjectGlobalCss: true }
);
